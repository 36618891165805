import React, { useRef, useState } from 'react'
import "../css/DragableDiv.css"

const DragableDiv = ({ text, position, onClick }) => {
  const ref = useRef(null);
  const [styleLeft, setStyleLeft] = useState("0");
  const [styleTop, setStyleTop] = useState("0");

  let pos3 = 0, pos4 = 0;
  const dragMouseDown = (e) => {
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  const elementDrag = (e) => {
    e.preventDefault();
    const elmnt = ref.current
    // calculate the new cursor position:
    // set the element's new position:
    setStyleLeft((elmnt.offsetLeft - (pos3 - e.clientX)) + "px")
    setStyleTop((elmnt.offsetTop - (pos4 - e.clientY)) + "px")
    pos3 = e.clientX;
    pos4 = e.clientY;
  }

  const closeDragElement = () => {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }


  return (
    <div className='dragable' style={{ left: styleLeft, top: styleTop }} ref={ref} onMouseDown={dragMouseDown} >
      <div className={`image--text ${position}`} onClick={onClick}>{text}</div>
    </div>
  )
}

export default DragableDiv