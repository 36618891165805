import React from "react";
import logo from "../images/Troll_Face.png"
import "../css/Navbar.css"

function Navbar() {
    return (
        <header className="navbar">
            <div className="logo">
                <img src={logo} className="logo-image" alt="logo" />
                <p>Meme Generator</p>
            </div>
            <p className="logo-other-title">
                @__Abuti__
            </p>
        </header>
    )
}

export default Navbar;