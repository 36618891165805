import React, { useState, useEffect } from "react"
import "../css/Main.css"
import btn_img from "../images/starry-night.png"
import axios from "axios"
import html2canvas from "html2canvas"
import JSConfetti from "js-confetti"
import DragableDiv from "./DragableDiv"

function Main() {

    const [allMemes, setAllMemes] = useState([]);
    const [confetti, setConfetti] = useState();

    useEffect(() => {
        setConfetti(new JSConfetti());
    }, [])

    const onConfetti = () => {
        confetti.addConfetti({
            emojis: ['🌈', '😆', '💥', '😎', '😁', '🤣'],
            emojiSize: 30,
            confettiRadius: 4,
            confettiNumber: 300,
        })
    }

    useEffect(() => {
        axios("https://api.imgflip.com/get_memes")
            .then(({ data }) => data)
            .then(({ data: { memes } }) => setAllMemes(memes));
    }, [])

    const [meme, setMemeData] = useState({
        url: "https://i.imgflip.com/hmt3v.jpg",
        name: "Abuti Sheba"
    });

    const [memeText, setMemeText] = useState(["Abuti"]);
    const [currentText, setCurrentText] = useState({ id: 0, value: memeText[0] });

    const onAddText = () => {
        setMemeText(prevMemeText => {
            return [...prevMemeText, `text${prevMemeText.length}`];
        });
    }

    const onTextChange = (event) => setMemeText(prevMemeText => {
        const { value } = event.target;
        setCurrentText(prevCurrent => ({ ...prevCurrent, value }));
        prevMemeText[currentText.id] = value;
        return [...prevMemeText];
    })

    const onTextClick = (id, value) => setCurrentText({ id, value });

    const getImageData = () => {
        if (!allMemes.length) return;

        const randomIndex = Math.floor(Math.random() * allMemes.length)
        const { url, name } = allMemes[randomIndex]
        return { url, name };
    }

    const onSetImageData = () => setMemeData(prevMemeData => ({ ...prevMemeData, ...getImageData() }));

    const [downloadHref, setTestHref] = useState("#");
    const onDownload = () => {
        const element = document.getElementById("meme");
        html2canvas(element, {
            logging: true,
            letterRendering: 1,
            allowTaint: false,
            useCORS: true
        }).then(canvas => {
            onConfetti();
            clickDownloader(canvas.toDataURL("png"));
        });
    };

    const clickDownloader = (uri) => {
        setTestHref(uri);
        const element = document.getElementById("meme-downloader");
        element.click();
    }

    return (
        <section className="main--section">
            <div className="main--container">
                <form onSubmit={(event) => event.preventDefault()}>
                    <div className="main--inputs">
                        <input type="text" name="topText" value={currentText.value} onChange={onTextChange} />
                        <button onClick={onAddText} className="main--btn-add">Add</button>
                    </div>
                    <button onClick={onSetImageData} className="main--btn">Get a new meme image <img src={btn_img} alt="#"></img></button>
                </form>
                <div className="image--container" id="meme">
                    {memeText.map((text, index) => <DragableDiv onClick={() => onTextClick(index, text)} key={index} position="top" text={text} />)}
                    <img src={meme.url} alt="#"></img>
                </div>
                <div className="image--downloader">
                    <a href={downloadHref} id="meme-downloader" download={`meme_${meme.name}.png`} >download</a>
                </div>
                <button onClick={onDownload} className="main--btn">Download <img src={btn_img} alt="#"></img></button>
            </div>
        </section>
    )
}

export default Main